import type { AppBarVariant } from "scmp-app/components/app-bar/types";
import type { HomeResponsiveFeature } from "scmp-app/components/home/header/types";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";

import type { ActionButtonProps } from "./styles";

export type Feature =
  | "article-speech"
  | "content-bookmark"
  | "content-comment"
  | "content-share"
  | "dashboard"
  | "hamburger-menu"
  | "logo-link-style"
  | "logo-link"
  | "my-news"
  | "search"
  | "subscription"
  | "text-link-style";

export const PositionVariants = ["left", "right"] as const;

export type PositionVariant = (typeof PositionVariants)[number];

type PositionVariantToFeatures = {
  left: Array<Exclude<Feature, "article-speech">>;
  right: Array<Feature>;
};

export type ResponsiveFeature = Partial<
  ResponsiveVariants<{
    default: PositionVariantToFeatures;
    nonSubscriberSwapped?: PositionVariantToFeatures;
    swapped?: PositionVariantToFeatures;
  }>
>;

export type HeaderConfiguration = {
  appBarStickyScrollHeight?: number;
  appBarVariant?: AppBarVariant;
  homeResponsiveFeatures?: HomeResponsiveFeature;
  responsiveFeatures?: ResponsiveFeature;
};

export type FeatureConfiguration = {
  isEnabled: boolean;
  responsiveVariants: ActionButtonProps["$responsiveVariants"];
};

export type PositionVariantToFeatureConfiguration = Record<PositionVariant, FeatureConfiguration>;
